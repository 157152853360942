.navbar-container{
  width: 100%;
display: flex;
flex-direction: column;
   justify-content: space-between;
    align-items: center;
}
nav{
  width: 100%;

}
.collapse{
  width: 100%;
  display: flex;
   justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}
.logo__container{
    width: 100px;
    height: 60px;
}
.logo__container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.drop{
  margin-bottom: 1rem;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
/* .nav{
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    
  } */
 


 @media  (max-width: 576px) {
  /*Nav button and close icon*/
  .collapse{
    width: 100%;
    display: block;
    padding: 0;
  
  }
  .logo__container{
     display: none;
  }
  .ico{
    display: none;
  }
  .bar{
    cursor: pointer;
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .close{
    font-size: 1.5rem;
    
  } 

  }