.login {
   height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;

}

.login__form {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    box-shadow: 0px 8px 20px -8px lightgray;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 20px 10px;
    font-family: var(--font-raleway);

}

.login__header {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    font-family: 'Mulish' sans-serif;
    color: black;
    padding: 10px 0;
}

.input input {
    outline: none;
    width: 300px;
    padding: 15px 30px;
    border-radius: 10px;
    margin: 10px 0;
    border: 1px solid #00000012;
    background-color: #ffffff;

}
.loading__image{
    width: 60px;
    height: 60px;
}
.login__error {
    color: red;
    font-size: 14px;
}

.login__submit{
 background: #5D8DD6;
border-radius: 10px;
color: #FFFFFF;
margin:10px 0px;
padding: 10px 20px;
border: #5D8DD6;
width: 150px;
cursor: pointer;
-webkit-transition: all .8s ease-in-out;
-moz-transition: all .8s ease-in-out;
-ms-transition: all .8s ease-in-out;
-o-transition: all .8s ease-in-out;
transition: all .8s ease-in-out;
}

.login__submit:hover{
   color: #5D8DD6;
}

.login__support {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.login__supportLink {
    text-decoration: none;
    color: #5D8DD6;
 margin:10px 0;
 font-size: 13px;
}

.login__show-password {
    color: #565656;
    font-size: 25px;
    cursor: pointer;
   position: absolute;
   margin-top: 20px;
   margin-left: -28px;
}

@media (max-width:570px){
    .login__form {
        width: 90%;
        
    
    } 
    }