.about{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   margin: 0;
}

.section{
    width: 100%;
 
}

.activitiesContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.team{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.paragraph{
    width: 100%; 
    /* border: 1px solid rgb(84, 50, 205);  */
}

.subsection{
width: 100%;
}
.aside{
    flex: 1;
}
.ceo{
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
}

@media (max-width: 576px) {
    .section{
        width: 100%;
        }
        .aside{
            flex: 0;
        }
    .activitiesContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .team{
        width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        align-items:center;
        padding: 0;
    }
    .ceo{
        display: flex;
        width: 350px;
        justify-content: center;
        border-radius: 10px;
        height: 50%;
        
    }
  
  }