.footer {
    /* background-color: rgba(15, 13, 13,0.3); */
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    padding: .5rem 0;
    width: 100%;
    height: 100%;
  } 

  .links-container{
     width: 100%;
    display: flex;
    flex-direction:row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: .5rem;
  }
  .tex{
      display: flex;
      align-self: center;
      margin-top: .5rem;
  }

  .icons span{
      margin: .3rem;
  }