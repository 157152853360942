.friend__details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
.friend__card{
    width: 50%;
    font-size: 17px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid lightgrey;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
}
.card__description{
    padding: 15px 10px;
    font-size: 16px;
}
@media (max-width:570px){
    .friend__card{
        width: 90%;
    }
    .card__description{
        padding: 15px 10px;
        font-size: 18px;
    }
}

/* ==================================================CREATE FRIEND=========================== */
.createPost{
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        width: 100%;
        margin: 0;
        padding: 15px 0;

    }
    .createPost__header > h2{
        align-items: center;
        font-size: 15px;
        color: #4db5ff;
        }
    .createPost__form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        /* background-color: #5D8DD6;  */
        box-shadow: 0px 8px 20px -8px #000;
        border-radius: 10px;
        font-family: var(--font-raleway);
        padding: 15px 30px;
      
        
    
    }

.createPost__input select{
    width: 100%;
   margin: 5px 0;
   padding:  7px 0;
   outline: none;
   border-radius: 10px;
   border: 1px solid #00000012;
   background-color: #ffffff;
}
.createPost__input textarea{
    width: 100%;
   margin: 5px 0;
   padding:  7px 0;
   outline: none;
   border-radius: 10px;
   border: 1px solid #00000012;
   background-color: #ffffff;
}
.preview__container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.friendData__create_post{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}
.createPost__label{
    padding-left: 10px;
    color: #fff;
    font-size: 14px;
}
.createPost__header > h3{
font-size: 15px;
color: #fff;
}
.image__uploadContainer > h4{
    font-size: 12px;
    color: #4db5ff;  
}
.image__uploadContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-start;
    margin-left: 20px;
    padding: 10px 0;
}

.preview__imageContainer{
    height: 65px;
    width: 65px;
    
}
.createPost__preview-image{
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  .createPost__preview-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
.previewImage{
    cursor: pointer;
    height: 60px;
    width:60px;
    object-fit: cover;
}
.close__prevIcon{
position: relative;
margin-top: -130px;
margin-right: -45px;
cursor: pointer;
}
/* .createPost__select{
    outline: none;
     width: 100%;
    padding: 0px 15px;
    border-radius: 10px;
    background-color: #fff;
    margin: 10px 0;
    border: 1px solid yellowgreen;
    
    
    } */
.createPost__input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
   margin: 0;
    outline: none;
    width: 300px;
    /* border: 1px solid yellowgreen; */
    }
.createPost__input input{
   width: 100%;
   margin: 5px 0;
   padding:  7px 0;
   outline: none;
   border-radius: 10px;
   border: 1px solid #00000012;
   background-color: #ffffff;
    
    }
.createPost__birthday{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
   margin: 0;
    padding: 8px 0;
    outline: none;
    width: 300px;

    }
.createPost__birthday input{
   margin: 7px 0;
    padding:  10px 0;
    outline: none;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #00000012;
    background-color: #ffffff;
   
    
    }
    /* option, select{
        font-size: 12px;
    } */
::placeholder{
    padding-left: 10px;
}
/* 
.checkbox__container{
    display: flex;
    justify-content: center;
    align-items: center; 
}
.checkbox__input {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 10px 0;
}
.checkbox__input > input{
    padding: 20px;
}
.checkbox__input > label{
    font-size: 12px;
} */

.react-tel-input {
    .form-control {
      .special-label{
    background-color: red;
}
    }
  }

  .createPost__submit{
    background: #FFF;
   border-radius: 10px;
   color: #565656;
   margin:10px 0px;
   border: none;
   padding: 10px 20px;
   width: 180px;
   cursor: pointer;
   -webkit-transition: all .8s ease-in-out;
   -moz-transition: all .8s ease-in-out;
   -ms-transition: all .8s ease-in-out;
   -o-transition: all .8s ease-in-out;
   transition: all .8s ease-in-out;
   }

@media screen and (max-width:600px) {
    .checkbox__input {
      flex-direction: column;
    } 
}