
.card{
    width: 100%;
    margin: 1rem;
}

/* .asideImage{
    width: 80%;
} */
.asideImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media only screen and (max-width: 400px) {
    .asideImage img{
        width: 100%;
        height: 20%;
    }

  
  }