.asideFloat{
    width: 100%;
    height: 200px;
    object-fit: contain;
    margin: 0;
}
.asideCard{
    width: 100%;
    padding: 1rem;
}


@media (max-width: 600px) {
    .asideImage img{
        width: 100%;
        height: 20%;
    }

    .asideCard{
        width: 350px;
        padding: 1rem;
      
    }
  }