@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');



:root {

    --font-base: 'Roboto', sans-serif;
    --font-alt: 'Mulish', sans-serif;
    --font-pop: 'Poppins', sans-serif;
    --normal-font: 100;
    --almost-regular: 300;
    --regular-font: 400;
    --medium-font: 500;
    --bold-font: 700;
  
 
    --color-golden: #FD951C;
    --category-heading:#50565A;
    --color-blur: #D3D3E2;
    --color-neutral: #171725;
    --color-blue: #50B5FF;
    --color-subtitle: #3B3D41;
    --color-white: #FFFFFF;
    --color-black: #50565A;
    --color-red: #D81921;
    --color-directions: #191B20;
  }

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  ::-webkit-scrollbar{
    display: none;
  }
  body{
    height: 100%;
}

/* .app{
  background-color: rgb(198, 201, 204);
} */

.main-container{
  padding: 3rem 0;
   
}
  
.paragraph{
  font-size: 18px;
  font-family: var(--font-base);
  font-weight: var(--regular-font);
  line-height: 26px;
}

.section{
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 0;
 
}
.subsection{
margin-right: 1rem;
padding: 1rem;
border-radius: 5px;
width: 100%;
}
/* .aside{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
flex: 1;
padding-right: 1rem;
padding: 2rem;
border-radius: 5px;
margin-left: 2rem;
} */

.asideTitle{
  font-size: 20px;
}

.asideText{
  font-size: 25px;
font-size: var(--font-base);
font-weight: var(--almost-regular);
line-height: 20px;
}

.link{
  text-decoration: none;
  margin: .5rem;
}
/* 
.activitiesContainer{
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;

} */

.activitiesContainer{
  width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-around;
align-items: flex-start;
padding: 1rem;



}


@media screen and (max-width: 600px) {
  .main-container{
   padding: 30px 20px;
     
  }
  .section{
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .aside{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
  border-radius: 5px;
  align-items: center;
  }

  .asideText{
    font-size: 25px;
  font-size: var(--font-base);
  font-weight: var(--almost-regular);
  line-height: 20px;
  }
}