.card{
    width: 500px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.staffImage{

    width: 120px;
    height: 130px;
   
}
.staffImage img{
   width:100% ;
    height: 100%;
    object-fit: cover;
    
    
}
@media (max-width: 578px){
    .card {
        width: 330px;
    }
    .asideImage img{
        width: 100%;
        height: 20%;
    }

  
  }