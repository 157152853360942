.home{
    width: 100%;
}
section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}


.paragragh{
    padding: 2rem;
}

@media only screen and (max-width: 600px) {
    section{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
       
    }

 
  }