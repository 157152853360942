.profile__container{
  height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}
.profile__dashboard{
  width: 100%;
  display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
}


.profile__button {
  background: #e90606;
  border: none;
  outline: none;
  /* border-radius: 10px; */
  color: #FFFFFF;
  padding: 10px 20px;
  margin-right: 30px;
  -webkit-transition: all .8s ease-in-out;
  -moz-transition: all .8s ease-in-out;
  -ms-transition: all .8s ease-in-out;
  -o-transition: all .8s ease-in-out;
  transition: all .8s ease-in-out;
  font-family: var(--font-poppins);
  text-transform: capitalize;
  align-self: flex-end;
}
.profile__link {
  text-decoration: none;
  background: #5D8DD6;
  border: none;
  outline: none;
  /* border-radius: 10px; */
  color: #FFFFFF;
  padding: 10px 20px;
  -webkit-transition: all .8s ease-in-out;
  -moz-transition: all .8s ease-in-out;
  -ms-transition: all .8s ease-in-out;
  -o-transition: all .8s ease-in-out;
  transition: all .8s ease-in-out;
  font-family: var(--font-poppins);
  text-transform: capitalize;
}



/* =========== MEDIA QUERIES (SMALL DEVICES ============== */

@media screen and (max-width: 600px){
  .profile__container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
}

.profile{
  border-radius: 50rem;
  height: 50%;
  width: 150px;
  margin-top: 3rem;

 }
}
