.violenceContainer{
    width: 100%;
   display:flex;
   flex-wrap: wrap;
   justify-content: center;
   
   align-items: flex-start;
   padding: 20px 0;
  
    
    
}