.carouselCard{
    height: 200px;
    width: 100%;
}
.carouselCardImage{
    height: 70vh;
}

.carouselCardImage img{
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 400px) {
    .carouselCardImage{
        height: 200px;
    }
  }