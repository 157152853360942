.contact{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.contact-header{
    width: 100%;
    margin-bottom: 18rem;
text-align: center;
font-size: 22px;
font-size: var(--font-base);
font-weight: var( --medium-font);
}
.sentence{
    font-size: 22px;
    font-family: var(--font-base);
    font-weight: var( --medium-font); 
}
.contact__details{
   
    font-weight: 500;
    font-size: 18px;
    color: #000;
    padding: 0 10px;
  
   ;
 }
 .subtitle{
    font-weight: 400;
    font-size: 18px;
    width: 100%;
    
   margin: 3px 0;
 }
.whatsappPost__button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: none;
  }
.contact-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

padding: 2rem;
border-radius: 5px;
}
.title{
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.social-media{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.social-media span{
    margin-right: 1rem;
}
.tex{
    margin-bottom: 3rem;
}
.applications {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 400px) {
    .contact-header{
       
        margin-bottom: 3rem;
    
    }
       
    }